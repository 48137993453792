html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

body {
	font-size: 1.6rem;
	margin: 0;
}

h1 {
	margin: 0;
}