.аpp {
  /* min-height: 100vh; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.App-header {
  padding: 1em 1em 0;
}

/* Typography */
html {
  font-family: sans-serif;
}

body,
form,
table,
input,
textarea,
select {
  font: 'inherit';
}

/* Reset */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
select,
input {
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

div,
section,
header,
footer {
  transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
